.introduction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: $ui-base-color;

  @media screen and (max-width: 920px) {
    display: block !important;
  }

  &__pager {
    background: darken($ui-base-color, 8%);
    box-shadow: 0 0 15px rgba($base-shadow-color, 0.2);
    overflow: hidden;
  }

  &__pager,
  &__frame {
    border-radius: 10px;
    width: 50vw;
    min-width: 920px;

    @media screen and (max-width: 920px) {
      min-width: 0;
      width: 100%;
      border-radius: 0;
      box-shadow: none;
    }
  }

  &__frame-wrapper {
    opacity: 0;
    transition: opacity 500ms linear;

    &.active {
      opacity: 1;
      transition: opacity 50ms linear;
    }
  }

  &__frame {
    overflow: hidden;
  }

  &__illustration {
    height: 50vh;

    @media screen and (max-width: 630px) {
      height: auto;
    }

    img {
      object-fit: cover;
      display: block;
      margin: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    border-top: 2px solid $ui-highlight-color;

    &--columnized {
      display: flex;

      & > div {
        flex: 1 1 33.33%;
        text-align: center;
        padding: 25px;
        padding-bottom: 30px;
      }

      @media screen and (max-width: 630px) {
        display: block;
        padding: 15px 0;
        padding-bottom: 20px;

        & > div {
          padding: 10px 25px;
        }
      }
    }

    h3 {
      font-size: 24px;
      line-height: 1.5;
      font-weight: 700;
      margin-bottom: 10px;
    }

    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: $darker-text-color;

      code {
        display: inline-block;
        background: darken($ui-base-color, 8%);
        font-size: 15px;
        border: 1px solid lighten($ui-base-color, 8%);
        border-radius: 2px;
        padding: 1px 3px;
      }
    }

    &--centered {
      padding: 25px;
      padding-bottom: 30px;
      text-align: center;
    }
  }

  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;

    @media screen and (max-width: 630px) {
      display: none;
    }
  }

  &__dot {
    width: 14px;
    height: 14px;
    border-radius: 14px;
    border: 1px solid $ui-highlight-color;
    background: transparent;
    margin: 0 3px;
    cursor: pointer;

    &:hover {
      background: lighten($ui-base-color, 8%);
    }

    &.active {
      cursor: default;
      background: $ui-highlight-color;
    }
  }

  &__action {
    padding: 25px;
    padding-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
